import React from "react";
import { Link } from "react-router-dom";
import CardTable from "./Components/CardTable";
import Plot from "./Components/Plots";
import Results from "./Components/Results";
import Table from "./Components/Table";
import { useDashboard } from "./Hooks";

const Dashboard = () => {
  const { data, loading } = useDashboard({ api: { route: "/dashboard" } });
  if (loading) {
    return <>Loading ...</>;
  }
  return (
    <>
      <div className="row g-3 mb-3">
        <CardTable data={data} />
      </div>
      <div className="row mb-3">
        <div className="col">
          <Plot label="درآمد هفتگی" data={data.chart} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
