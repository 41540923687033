import React from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";

const Layout = ({ children }) => {
  return (
    <main className="main" id="top">
      <div className="container" data-layout="container">
        <Sidebar />
        <div className="content">
          <Navbar />
          {children}
        </div>
      </div>
    </main>
  );
};

export default Layout;
