import moment from "jalali-moment";

export const timeSince = date => {
  if (date) {
    let data = new Date(date);

    let seconds = Math.floor((new Date() - data) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " سال پیش";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " ماه پیش";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " روز پیش";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " ساعت پیش";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " دقیقه پیش";
    }
    return "اخیرا";
  } else {
    return "none";
  }
};
export const cutText = (txt, length = 20) => {
  if (txt) {
    return `${txt.slice(0, length)} ...`;
  }
};
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};
export const ConvertDate = date => {
  if (date) {
    return moment.from(date, "fa", "YYYY/MM/DD").format("YYYY/MM/DD");
  } else {
    return null;
  }
};
export const sortSettings = (arr = []) => {
  let obj = {};
  arr.map(item => {
    obj = { ...obj, [item.title]: item.value };
  });
  return obj;
};
export const ToDateJalali = date => {
  if (date) {
    return moment(date).locale("fa").format("YYYY/MM/DD");
  } else {
    return null;
  }
};
export const ToTimeJalali = date => {
  if (date) {
    return moment(date).locale("fa").format(" HH:mm:ss - YYYY/MM/DD");
  } else {
    return null;
  }
};
export const convertKeys = (arr = [], order = {}) => {
  let keysOrder = Object.keys(order);
  const renderObj = obj => {
    return keysOrder.reduce((prev, currentValue) => {
      return {
        ...prev,
        [order[currentValue]]: obj[currentValue],
      };
    }, {});
  };
  return arr.map(element => renderObj(element));
};
export const ToDateDayMonthJalali = date => {
  if (date) {
    return moment(date).locale("fa").format("MM/DD");
  } else {
    return null;
  }
};
export const renderPrice = (x = "", d = "/") => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, d);
};
