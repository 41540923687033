import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMainContext } from "../../../Context";

export const useOrderDetails = ({ api, key }) => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { post } = useMainContext();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);
    const { data } = await post(api?.route, { [key]: id });
    setData(data);
    setLoading(false);
  };
  const renderDetails = (item) => {
    switch (item.status) {
      case "pending":
        return {
          text: "پرداخت نشده",
          access: [],
          status: "error",
        };
      case "reject":
        return {
          text: "رد شده",
          access: ["details"],
          status: "errors",
        };
      case "payed":
        // both
        return {
          text: "پرداخت شده",
          access: ["details", "request"],
          status: "success",
        };
        break;
      case "succses":
        // both
        return {
          text: "موفق",
          access: ["details", "request"],
          status: "success",
        };

      default:
        return {
          text: "پرداخت ناموفق",
          access: [],
          status: "error",
        };
    }
  };
  return {
    renderDetails,
    loading,
    data,
    id,
  };
};
