import React from "react";
import { useAuth } from "./Hooks";

const Login = () => {
  const { handler, loading, form, verifyCode } = useAuth();
  return (
    <div className="container-fluid">
      <div className="row min-vh-100 flex-center g-0">
        <div className="col-lg-8 col-xxl-5 py-3 position-relative">
          <img
            className="bg-auth-circle-shape"
            src="../../../assets/img/icons/spot-illustrations/bg-shape.png"
            alt
            width={250}
          />
          <img
            className="bg-auth-circle-shape-2"
            src="../../../assets/img/icons/spot-illustrations/shape-1.png"
            alt
            width={150}
          />
          <div className="card overflow-hidden z-index-1">
            <div className="card-body p-0">
              <div className="row g-0 h-100">
                <div className="col-md-5 text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                    <div
                      className="bg-holder bg-auth-card-shape"
                      style={{
                        backgroundImage:
                          "url(../../../assets/img/icons/spot-illustrations/half-circle.png)",
                      }}
                    />
                    {/*/.bg-holder*/}
                    <div className="z-index-1 position-relative">
                      <a
                        className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder"
                        href="../../../index.html"
                      >
                        تجارکو
                      </a>
                      <p className="opacity-75 text-white">خوش آمدید!</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 d-flex flex-center">
                  <div className="p-4 p-md-5 flex-grow-1">
                    <div className="row flex-between-center">
                      <div className="col-auto">
                        <h3>ورود به حساب کاربری</h3>
                      </div>
                    </div>
                    <div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="card-email">
                          ایمیل
                        </label>
                        <input
                          className="form-control"
                          id="card-email"
                          type="email"
                          disabled={form.code}
                          onChange={({ target: { value } }) =>
                            handler("email", value)
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <label className="form-label" htmlFor="card-password">
                            رمز عبور
                          </label>
                        </div>
                        <input
                          className="form-control"
                          id="card-password"
                          type="password"
                          onChange={({ target: { value } }) =>
                            handler("password", value)
                          }
                          onKeyDown={event => {
                            if (event.key === "Enter") {
                              verifyCode();
                            }
                          }}
                        />
                      </div>

                      <>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary d-block w-100 mt-3"
                            type="submit"
                            name="submit"
                            onClick={verifyCode}
                            disabled={loading.verify}
                          >
                            {loading.verify ? "loading ..." : "ورود"}
                          </button>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
