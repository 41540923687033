import React from "react";
import { useSearchParams } from "react-router-dom";

const Filter = ({
  filters = [],
  searchParams,
  setSearchParams,
  filterDelete,
  setAreUSure,
  changeObj,
}) => {
  //
  const renderItem = filter => {
    switch (filter.type) {
      case "select":
        return (
          <div className="col-lg-6">
            <select
              className="form-control"
              defaultValue={searchParams.get(filter.key) || ""}
              onChange={({ target: { value } }) => {
                if (value == "all") {
                  filterDelete(filter.key);
                } else {
                  changeObj([filter.key], value);
                }
              }}
            >
              <option value={""}>همه</option>
              {filter.options.map(op => {
                return <option value={op.key}>{op.label}</option>;
              })}
            </select>
          </div>
        );
        break;
      case "btn":
        if (searchParams.get(filter.statement.key) == filter.statement.value) {
          return (
            <div className="col-lg-6">
              <button
                onClick={() => {
                  setAreUSure({
                    status: true,
                    type: "delete",
                    action: { route: filter.route, data: {} },
                  });
                }}
                className="btn btn-danger "
                style={{ whiteSpace: "nowrap" }}
              >
                {filter.text}
              </button>
            </div>
          );
        }

      default:
        break;
    }
  };
  return (
    <div className="row" style={{}}>
      {filters.map(filter => {
        return renderItem(filter);
      })}
    </div>
  );
};

export default Filter;
