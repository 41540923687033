import React from "react";
import {
  cutText,
  renderPrice,
  ToDateJalali,
  ToTimeJalali,
} from "../../Utils/functions";
import { useOrderDetails } from "./Hooks";

const OrderDetails = ({ option }) => {
  const { data, loading, id,renderDetails } = useOrderDetails(option);
  if (loading) {
    return <h3 className="text-center">Loading ...</h3>;
  }
  console.log("asdasdasdasdasd ", data);

  return (
    <div className="content">
      <div className="card mb-3">
        <div className="card-body">
          <div className="table-responsive fs--1">
            <div className="modal-padding">
              <div className="modal-title">
                <h4 className="fs-14 fw-600">جزئیات سرمایه‌گذاری</h4>
              </div>
              <div className="table-content mt-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">شناسه</th>
                      <th scope="col">تاریخ درخواست</th>
                      <th scope="col">نوع درخواست</th>
                      <th scope="col">مبلغ</th>
                      <th scope="col">وضعیت</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                      data?.map((item) => {
                        const { status, text } = renderDetails(item);
                        return (
                          <tr>
                            <th scope="row">{item.code}</th>
                            <td>{ToDateJalali(item.created_at)}</td>
                            <td>{item.type == "in" ? "واریز" : "برداشت"}</td>
                            <td>{renderPrice(item.amount, ",")}</td>
                            <td>
                              <span
                                className="badge-status"
                                data-status={status}
                                ml={"15px"}></span>
                              {text}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
