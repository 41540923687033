import React, { useEffect } from "react";
import { useTable } from "./Hooks";
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom";
import ModalContent from "./Components/ModalContent";
import Filter from "./Components/Filter";

const Table = ({ options }) => {
  const { pathname } = useLocation();
  const {
    getData,
    data,
    loading,
    renderCloumn,
    renderAction,
    areUSure,
    setAreUSure,
    LoadingDelete,
    deleteRow,
    isIn,
    toggle,
    length,
    selected,
    searchParams,
    setSearchParams,
    changeObj,
    filterDelete,
  } = useTable({
    route: options.api.route,
    elements: [],
    selectAble: options.selectAble || {},
    filters: options.filters
      ? [...options.filters, { key: "page" }, { key: "limit" }]
      : [{ key: "page", key: "limit" }],
    dashboard: options.dashboard || {},
  });
  const selectAble = options.selectAble || {};
  const filters = options.filters
    ? [...options.filters, { key: "page" }, { key: "limit" }]
    : [{ key: "page", key: "limit" }];
  useEffect(() => {
    if (!areUSure.status && !options.dashboard) {
      getData();
    }
  }, [pathname, areUSure.status, searchParams]);

  const pagenate = () => {
    return (
      <div className=" mt-4 mb-1">
        <div className="row justify-content-center">
          <Pagination
            activePage={data.current_page}
            itemsCountPerPage={data.per_page}
            totalItemsCount={data.total}
            onChange={(data) => changeObj("page", data)}
            itemClass={"page-item"}
            linkClass={"page-link"}
          />
        </div>
      </div>
    );
  };
  console.log("o", options);
  const renderData = () => {
    if (options?.dashboard?.data) {
      return options.dashboard.data || [];
    } else {
      return data.data || [];
    }
    return [];
  };
  return (
    <>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title mb-2">{options.label}</h4>
            <div className="row  align-items-center">
              {!options.dashboard && (
                <div className="col-2 my-3">
                  <input
                    type="search"
                    placeholder="جستجو ..."
                    className="form-control"
                    onChange={({ target: { value } }) => {
                      if (value.length % 2 == 0) {
                        getData({ page: data.current_page, search: value });
                      }
                    }}
                  />
                </div>
              )}

              {filters.length > 0 && (
                <div className="col-6">
                  <Filter
                    filterDelete={filterDelete}
                    filters={filters}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    setAreUSure={setAreUSure}
                    changeObj={changeObj}
                  />
                </div>
              )}

              {selectAble.status && length() > 0 && (
                <div className="col-4 d-flex justify-content-end">
                  <div className=" ms-3" id="bulk-select-actions">
                    <div className="d-flex">
                      <button
                        className="btn btn-falcon-danger btn-sm ms-2"
                        type="button"
                        onClick={() => {
                          setAreUSure({
                            status: true,
                            type: "multiSelect",
                            action: {
                              route: selectAble.route,
                              subRoute: selectAble.subRoute,
                            },
                            inintal: { crms: selected },
                          });
                        }}>
                        {selectAble.text}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {loading && !options.dashboard ? (
              <div className="d-flex justify-content-center">
                {" "}
                <span
                  class="spinner-border spinner-border-lg"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <>
                <div className="table-responsive scrollbar">
                  <table className="table table-bordered table-striped fs--1 mb-0">
                    <thead className="bg-200 text-900">
                      <tr>
                        {/* <th>
                          {selectAble.status ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={toggleAll}
                              checked={isSelectedAll()}
                            />
                          ) : (
                            <th>#</th>
                          )}
                        </th> */}
                        <th>#</th>

                        {options.header &&
                          options.header.map((head) => {
                            return <th>{head.label}</th>;
                          })}
                        {options.actions && options.actions.length > 0 ? (
                          <th></th>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody className="list">
                      {renderData().length == 0 ? (
                        options.emptyBox ? (
                          <tr>
                            <td colSpan={10}>{options.emptyBox()}</td>{" "}
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan={10}>
                              <div className="d-flex justify-content-center align-items-center">
                                <p className="text-center my-5">
                                  موردی یافت نشد
                                </p>
                              </div>
                            </td>
                          </tr>
                        )
                      ) : (
                        renderData().map((item, count) => {
                          return (
                            <tr>
                              <td>
                                {" "}
                                {selectAble.status ? (
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isIn(item.id)}
                                    onClick={() => toggle(item.id)}
                                    disabled={
                                      item[selectAble.statement.key] !==
                                      selectAble.statement.value
                                    }
                                  />
                                ) : (
                                  <>{count + 1}</>
                                )}
                              </td>
                              {options?.header.map((head) => {
                                return <td> {renderCloumn(item, head)}</td>;
                              })}
                              {options?.actions && (
                                <td>
                                  {" "}
                                  {options.actions &&
                                    options.actions.map((action) => {
                                      return renderAction(action, item);
                                    })}
                                </td>
                              )}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
                {!options.dashboard && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="ml-3">
                      <label htmlFor="">تعداد نمایش :</label>
                    </div>
                    <div style={{ flex: 1 }}>
                      <select
                        onChange={({ target: { value } }) => {
                          changeObj("limit", value);
                        }}
                        className="form-control w-11"
                        defaultValue={searchParams.get("limit")}>
                        {Array(8)
                          .fill(0)
                          .map((_i, d) => (d + 1) * 20)
                          .map((op) => (
                            <option key={op}>{op}</option>
                          ))}
                      </select>
                    </div>
                    {pagenate()}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ModalContent
        data={areUSure}
        setAreUSure={setAreUSure}
        LoadingDelete={LoadingDelete}
        deleteRow={deleteRow}
      />
    </>
  );
};

export default Table;
