import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";

const Keywords = ({ element, form, setForm }) => {
  const [word, setWord] = useState("");
  useEffect(() => {
    if (!form[element.key]) {
      setForm({ ...form, [element.key]: {} });
    }
  }, []);

  const addWordHandler = () => {
    if (element.nested) {
      if (form[element.nested][element.key]) {
        if (!form[element.nested][element.key].includes(word)) {
          setForm({
            ...form,
            [element.nested]: {
              ...form[element.nested],
              [element.key]: [
                ...form[element.nested][element.key],
                word,
              ].filter(Boolean),
            },
          });
          setWord("");
        } else {
          toast.error("this word is repeated");
        }
      } else {
        setForm({
          ...form,
          [element.nested]: {
            ...form[element.nested],
            [element.key]: [word].filter(Boolean),
          },
        });
      }
    } else {
      if (form[element.key]) {
        if (!form[element.key].includes(word)) {
          setForm({
            ...form,
            [element.key]: [...form[element.key], word].filter(Boolean),
          });
          setWord("");
        } else {
          toast.error("this word is repeated");
        }
      } else {
        setForm({
          ...form,
          [element.key]: [word].filter(Boolean),
        });
      }
    }
  };
  const deleteWord = wo => {
    if (element.nested) {
      setForm({
        ...form,
        [element.nested]: {
          ...form[element.nested],
          [element.key]: form[element.nested][element.key]
            .map(ele => {
              if (ele != wo) {
                return ele;
              }
            })
            .filter(Boolean),
        },
      });
    } else {
      setForm({
        ...form,
        [element.key]: form[element.key]
          .map(ele => {
            if (ele != wo) {
              return ele;
            }
          })
          .filter(Boolean),
      });
    }
  };
  const renderkeys = () => {
    if (form[element.nested]) {
      if (Array.isArray(form[element.nested][element.key])) {
        if (form[element.nested][element.key].length != 0) {
          return form[element.nested][element.key].map(item => {
            return (
              <button
                key={item}
                className="btn btn-outline-dark m-2"
                onClick={() => {
                  deleteWord(item);
                }}
              >
                <span>{item}</span>
                <span style={{ marginRight: "10px" }}>
                  <AiOutlineClose />
                </span>
              </button>
            );
          });
        } else {
          return (
            <h6 style={{ textAlign: "center", fontWeight: "400" }}>
              موردی یافت نشد
            </h6>
          );
        }
      } else {
        return (
          <h6 style={{ textAlign: "center", fontWeight: "400" }}>
            موردی یافت نشد
          </h6>
        );
      }
    } else {
      if (Array.isArray(form[element.key])) {
        if (form[element.key].length != 0) {
          return form[element.key].map(item => {
            return (
              <button
                className="btn btn-outline-dark m-2"
                onClick={() => {
                  deleteWord(item);
                }}
              >
                <span>{item}</span>
                <span style={{ marginRight: "10px" }}>
                  <AiOutlineClose />
                </span>
              </button>
            );
          });
        } else {
          return (
            <h6 style={{ textAlign: "center", fontWeight: "400" }}>
              موردی یافت نشد
            </h6>
          );
        }
      } else {
        return (
          <h6 style={{ textAlign: "center", fontWeight: "400" }}>
            موردی یافت نشد
          </h6>
        );
      }
    }
  };
  return (
    <div className="col-12 my-2">
      <div>
        <label style={{ fontWeight: "400" }}>{element.label}</label>
        <div className="d-flex mb-2">
          <input
            className="form-control"
            onChange={({ target: { value } }) => {
              setWord(value);
            }}
            value={word}
            onKeyDown={e => {
              if (e.key == "Enter") {
                addWordHandler();
              }
            }}
          />
          <button className="btn btn-primary mr-2" onClick={addWordHandler}>
            ثبت
          </button>
        </div>
      </div>
      <div className="border rounded p-2">{renderkeys()}</div>
    </div>
  );
};

export default Keywords;
