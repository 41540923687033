import { useState } from "react";
import { toast } from "react-toastify";
import { useMainContext } from "../../../Context";
import { post } from "../../../Server";
export const useAuth = () => {
  const [form, setForm] = useState({ email: "", password: "" });
  const { dispatch } = useMainContext();
  const [loading, setLoading] = useState({ verify: false });

  const verifyCode = async () => {
    setLoading({ verify: true });
    const { data, status } = await post(
      "/admin/login",
      {
        ...form,
      },
      true
    );
    setLoading({ verify: false });
    if (+status === 200) {
      dispatch("user", data);
      window.location.replace("/");
    }
  };
  const handler = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  return { handler, loading, verifyCode, form };
};
