import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useMainContext } from "../../Context";
import { stracture } from "../Panel";

const Sidebar = () => {
  const { pathname } = useLocation();
  const { logout, state } = useMainContext();
  return (
    <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
      <div className="d-flex align-items-center">
        <div className="toggle-icon-wrapper">
          <button
            className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            aria-label="ناوبری را تغییر دهید">
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        </div>
        <Link to={"/"} className="navbar-brand" href="index.html">
          <div className="d-flex align-items-center py-3">
            <span className="font-sans-serif">تجارکو</span>
          </div>
        </Link>
      </div>
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div className="navbar-vertical-content scrollbar">
          <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            {stracture.pages &&
              stracture.pages.map((page, count) => {
                if (page.nested) {
                  return (
                    <li className="nav-item">
                      <a
                        className="nav-link dropdown-indicator collapsed"
                        href={`#da-${count}`}
                        role="button"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-controls={`da-${count}`}>
                        <div className="d-flex align-items-center">
                          <span className="nav-link-icon">
                            <i className={page.icon} />
                            {/* <span class="fas fa-chart-pie"></span> Font Awesome fontawesome.com */}
                          </span>
                          <span className="nav-link-text ps-1">
                            {page.label}
                          </span>
                        </div>
                      </a>
                      <ul className="nav collapse " id={`da-${count}`}>
                        {page.options.map((option) => {
                          if (option.show) {
                            return (
                              <li className="nav-item">
                                <Link
                                  className={` nav-link ${
                                    option.route == pathname && "active"
                                  }`}
                                  to={option.route}
                                  aria-expanded="false">
                                  <div className="d-flex align-items-center">
                                    <span className="nav-link-text ps-1">
                                      {option.label}
                                    </span>
                                  </div>
                                </Link>
                                {/* more inner pages*/}
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <Link
                      class="nav-link"
                      to={page.route}
                      role="button"
                      aria-expanded="false">
                      <div class="d-flex align-items-center">
                        <span class="nav-link-icon">
                          <i className={page.icon} />
                        </span>
                        <span class="nav-link-text ps-1">{page.label}</span>
                      </div>{" "}
                    </Link>
                  );
                }
              })}

            <button
              class="nav-link btn"
              onClick={() => logout()}
              disabled={state.loading}>
              <div class="d-flex align-items-center">
                <span class="nav-link-icon">
                  <i className="fas fa-sign-out-alt" />
                </span>
                {state.loading ? (
                  <div className="d-flex align-items-center">
                    <span> در حال پردازش </span>
                  </div>
                ) : (
                  <>
                    <span>خروج از حساب</span>
                  </>
                )}
              </div>
            </button>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
