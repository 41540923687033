import React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import Layout from "../../Layouts";
import { sortSettings } from "../../Utils/functions";
import Dashboard from "../Dashboard";
import Form from "../Form";
import NotFound from "../NotFound";
import Support from "../Support";
import Surfaces from "../Surfaces";
import Table from "../Table";
export const stracture = {
  // fas fa-shopping-cart
  pages: [
    {
      label: "داشبورد",
      icon: "fab fa-trello",
      route: "/",
      nested: false,
      options: [
        {
          route: "/",
          type: "dashboard",
          elements: [],
        },
      ],
    },

    {
      icon: "fas fa-crown",
      nested: true,
      label: "پکیج سرمایه گذاری",
      options: [
        {
          route: "/package/create",
          type: "form",
          show: true,
          label: "ایجاد پکیج سرمایه گذاری",
          api: {
            route: "/package/store",
          },
          box: {
            route: "/package/list",
            title: "لیست پکیج سرمایه گذاری",
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "min_amount",
              label: "حداقل سرمایه (بر اساس دلار)",
            },

            {
              key: "interest_rates",
              label: "سود (درصد)",
            },
            {
              key: "period",
              label: "زمان(بر اساس روز)",
            },
          ],
        },
        {
          route: "/package/list",
          type: "table",
          show: true,
          label: "لیست پکیج سرمایه گذاری",
          api: {
            route: "/package/list",
          },
          actions: [
            {
              type: "delete",
              label: "حذف پکیج سرمایه گذاری",
              route: "/package/delete",
              key: "id",
            },
            {
              type: "show",
              link: "/package/update",
              label: "ویرایش پکیج",
            },
          ],
          header: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "min_amount",
              label: "حداقل سرمایه",
              amount: "$",
              type: "amount",
            },

            {
              key: "interest_rates",
              amount: "%",
              type: "amount",
              label: "سود",
            },
            {
              key: "period",
              amount: "روز",
              type: "amount",
              label: "زمان",
            },
            {
              key: "update_at",
              type: "date",
              label: "آخرین ویرایش",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
          ],
        },
        {
          route: "/package/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش پکیج سرمایه گذاری",
          box: {
            title: "لیست پکیج سرمایه گذاری",
            route: "/package/list",
          },
          emptyBox: () => {
            return (
              <div className="d-flex justify-content-center align-items-center">
                <p className="text-center my-5">پکیجی یافت نشد!</p>
              </div>
            );
          },
          api: {
            route: "/package/update",
            show: {
              route: "/package/show",
              key: "id",
            },
          },
          sortInint: (state) => {
            return {
              ...state,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "min_amount",
              label: "حداقل سرمایه (بر اساس دلار)",
            },

            {
              key: "interest_rates",
              label: "سود (درصد)",
            },
            {
              key: "period",
              label: "زمان(بر اساس روز)",
            },
          ],
        },
      ],
    },
    {
      label: "کاربران",
      nested: true,
      icon: "far fa-user",
      options: [
        {
          route: "/client/create",
          type: "form",
          show: true,
          label: "ایجاد کاربر",
          api: {
            route: "/client/store",
          },
          box: {
            route: "/client/list",
            title: "لیست کاربران",
          },
          form: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "email",
              label: "ایمیل",
            },
            {
              key: "isadmin",
              type: "toggle",
              label: "ادمین",
            },
            {
              key: "password",
              label: "کلمه عبور",
              exist: {
                key: "isadmin",
                value: "true",
              },
            },
          ],
        },
        {
          route: "/client/list",
          type: "table",
          show: true,
          label: "لیست کاربران",
          api: {
            route: "/client/list",
          },
          actions: [
            {
              type: "delete",
              label: "حذف کاربر",
              route: "/client/delete",
              key: "user_id",
            },
            {
              type: "toggle",
              depened: {
                key: "status",
                on: "غیر فعال کردن کاربر",
                off: "فعال کردن کاربر",
              },
              key: "user_id",
              route: "/client/toggle",
            },
            {
              type: "access",
              label: "generate password",
            },
            {
              type: "show",
              link: "/client/update",
              label: "show profile",
            },
          ],
          header: [
            {
              key: "fullname",
              label: "نام",
            },
            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "status",
              type: "status",
              label: "وضعیت",
            },
            {
              key: "isadmin",
              label: "نوع کاربر",
              type: "isadmin",
              default: "client",
            },
            {
              key: "identifer_count",
              label: "نعداد دعوت",
            },
            {
              key: "lastlogin",
              type: "date",
              label: "آخرین ورود",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "آخرین ویرایش",
            },
          ],
        },
        {
          route: "/client/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش کاربر",
          box: {
            title: "لیست کاربران",
            route: "/client/list",
          },
          api: {
            route: "/client/profile/edit",
            show: {
              route: "/client/profile",
              key: "user_id",
            },
          },
          sortInint: (state) => {
            return {
              ...state,
              user_id: state.id,
            };
          },
          form: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "email",
              label: "ایمیل",
            },
            {
              key: "isadmin",
              type: "toggle",
              label: "ادمین",
            },
            {
              key: "password",
              label: "کلمه عبور",
              exist: {
                key: "isadmin",
                value: "true",
              },
            },
          ],
        },
      ],
    },
    {
      label: "برداشت ها",
      nested: true,
      icon: "far fa-user",
      options: [
        {
          route: "/withdraw/list",
          type: "table",
          show: true,
          label: "لیست برداشت ها",
          api: {
            route: "withdraw/list",
          },
          filters: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  label: "در انتظار پرداخت",
                  key: "pending",
                },
                {
                  label: "پرداخت شده",
                  key: "reject",
                },
                {
                  label: "پرداخت شده",
                  key: "payed",
                },
              ],
            },
          ],
          actions: [
            {
              type: "showmodal",
              key: "id",
              route: "/withdraw/peyed",
              disabled: {
                key: "status",
                value: "pending",
              },
            },
            {
              type: "submit",
              key: "id",
              route: "/withdraw/peyed",
              disabled: {
                key: "status",
                value: "pending",
              },
            },
            {
              type: "reject",
              key: "id",
              route: "/withdraw/reject",
              disabled: {
                key: "status",
                value: "pending",
              },
            },
          ],
          header: [
            {
              key: "code",
              label: "کد درخواست",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
            {
              key: "fullname",
              label: "نام",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "mobile",
              label: "موبایل",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "email",
              label: "ایمیل",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "amount",
              label: "میزان برداشت (دلار)",
            },
            {
              key: "status",
              label: "وضعیت",
              type: "enum",
              enum: {
                pending: "در انتظار پرداخت",
                reject: "رد شده",
                payed: "پرداخت شده",
              },
            },
            {
              key: "updated_at",
              type: "date",
              label: "تاریخ تغیر وضعیت",
            },
          ],
        },
      ],
    },
    {
      label: "سرمایه گزاری ها",
      nested: true,
      icon: "far fa-user",
      options: [
        {
          route: "/order/new",
          type: "form",
          show: true,
          label: "ایجاد سرمایه گزاری",
          api: {
            route: "/order/new/manual",
          },
          box: {
            route: "/order/list",
            title: "لیست سرمایه گزاری ها",
          },
          form: [
            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "amount",
              label: "میزان سرمایه گزاری",
            },
            {
              key: "date",
              type: "date",
              single: true,
              label: "تاریخ سرمایه گزاری",
            },
          ],
        },
        {
          route: "/order/list",
          type: "table",
          show: true,
          label: "لیست سرمایه گزاری ها",
          api: {
            route: "/order/list",
          },
          filters: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  label: "پرداخت رها شده",
                  key: "abandoned",
                },
                {
                  label: "در انتظار پرداخت",
                  key: "pending",
                },
                {
                  label: "فعال",
                  key: "active",
                },
                {
                  label: "منقضی شده",
                  key: "experid",
                },
                {
                  label: "خطا در پرداخت",
                  key: "errorpeyed",
                },
              ],
            },
          ],
          actions: [
            {
              type: "show",
              link: "/orders",
              label: "جزییات شفارش",
              keyGo: "tracking_code",
            },
          ],
          header: [
            {
              key: "tracking_code",
              label: "کد پیگیری",
            },
            {
              key: "fullname",
              label: "نام",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "mobile",
              label: "موبایل",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "email",
              label: "ایمیل",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "dolar_amount",
              label: "میزان سرمایه گزاری  (دلار)",
            },
            {
              key: "rial_amount",
              label: "میزان سرمایه گزاری  (ریال)",
            },
            {
              key: "total_profit",
              label: "میزان کل سود   (دلار)",
            },
            {
              key: "status",
              type: "enum",
              label: "وضعیت",
              enum: {
                abandoned: "پرداخت رها شده",
                pending: "در انتظار پرداخت",
                active: "فعال",
                experid: "منقضی شده",
                errorpeyed: "خطا در پرداخت",
              },
            },
            {
              key: "start_date",
              label: "تاریخ شروع سرمایه گزاری",
              type: "exact-date",
              default: "client",
            },
            {
              key: "exp_date",
              label: "تاریخ پایان سرمایه گزاری",
              type: "exact-date",
              default: "client",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "آخرین تغیر وضعیت",
            },
          ],
        },
        {
          route: "/orders/:id",
          type: "surface",
          surfaceType: "orderDetails",
          label: "جزیيات سفارش",
          key: "tracking_code",
          api: {
            route: "/order/single",
          },
          header: [],
        },
      ],
    },
  ],
};
const Panel = () => {
  const renderRoute = (option) => {
    switch (option.type) {
      case "form":
        return (
          <Route
            path={option.route}
            element={
              <Layout stracture={stracture}>
                <Form options={option} />
              </Layout>
            }
          />
        );
      case "edit":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Form options={option} edit={true} />
              </Layout>
            }
          />
        );
      case "table":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Table options={option} />
              </Layout>
            }
          />
        );
      case "dashboard":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        );
      case "ticketing":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Support />
              </Layout>
            }
          />
        );
      case "surface":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Surfaces option={option} />
              </Layout>
            }
          />
        );

      default:
        break;
    }
  };
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        {stracture &&
          stracture.pages.map((page) => {
            return page.options.map((option) => {
              if (option) {
                return renderRoute(option);
              }
            });
          })}
      </Routes>
    </>
  );
};

export default Panel;
