import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { post } from "../../../../Server";
import { ToDateJalali } from "../../../../Utils/functions";
import CustomDatePicker from "../../../Form/Components/DatePicker";
import MultiSelect from "../../../Form/Components/MultiSelect";

const ModalContent = ({ data, setAreUSure, LoadingDelete, deleteRow }) => {
  useEffect(() => {
    setForm({ experters: [] });
  }, [LoadingDelete]);

  const [form, setForm] = useState({ experters: [] });
  const handleClose = () => setAreUSure({ ...data, status: false });
  console.log("form", form);
  switch (data.type) {
    case "delete":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">
                  آیا مطمئن هستید
                  <span className="text-primary">
                    {data && data?.data?.title}
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  خیر
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "بله"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "toggle":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">
                  آیا مطمئن هستید
                  <span className="text-primary">
                    {data && data.data.title}
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  خیر
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "بله"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
      break;
    case "select":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">{data.action.label}</div>
              </div>
              <div className="p-2">
                <select
                  className="form-control"
                  defaultValue={data.data[data.action.objectKey]}
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, [data.action.objectKey]: value });
                  }}>
                  {data.action.options.map((option) => {
                    return <option value={option.key}>{option.label}</option>;
                  })}
                </select>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data, form)}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "add":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">{data.action.label}</div>
              </div>
              <div className="p-2">
                <textarea
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    setForm({ ...form, [data.action.objectKey]: value });
                  }}></textarea>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteRow(data, form)}
                  disabled={!form[data.action.objectKey]}>
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "multiSelect":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">
                  کارشناس های مورد نظر خود را انتخاب کنید
                </div>
              </div>
              <div className="p-2">
                <SelectSaleexperts form={form} setForm={setForm} />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="flexCheckIndeterminate"
                    type="checkbox"
                    defaultValue
                    onChange={() => {
                      setForm({ ...form, all: !form.all });
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckIndeterminate">
                    تخصیص به همه کارشناسان به صورت رندوم
                  </label>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                {console.log("d", data)}
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                        action: {
                          ...data.action,
                          route: form.all
                            ? data.action.subRoute
                            : data.action.route,
                        },
                      },
                      { ...form, ...data.inintal }
                    )
                  }
                  // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "gift":
      console.log("d", data);
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">ثبت یا ویرایش تخفیف</div>
              </div>
              <div className="p-2">
                <CustomDatePicker
                  element={{
                    label: "تاریخ انقضا",
                    single: true,
                    key: "exp_date",
                  }}
                  form={form}
                  setForm={setForm}
                />
                <div className="form-group my-2">
                  <label htmlFor="exampleInputName1">مقدار تخفیف</label>
                  <input
                    onChange={({ target: { value } }) => {
                      setForm({ ...form, second_price: value });
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder={"200000"}
                    defaultValue={data?.data?.second_price?.price}
                  />
                </div>
                {data?.data?.second_price?.price && (
                  <div className="alert alert-danger d-flex justify-content-between">
                    <p className="m-0"> تخفیف قبلی </p>
                    <p className="m-0">
                      {" "}
                      {data?.data?.second_price?.price} -{" "}
                      {ToDateJalali(data?.data?.second_price?.exp)}
                    </p>
                  </div>
                )}
                {data?.data?.second_price?.price && (
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id="flexCheckIndeterminate"
                      type="checkbox"
                      defaultValue
                      onChange={() => {
                        if (form.end) {
                          let newState = { ...form };
                          delete newState.end;
                          setForm({ ...newState });
                        } else {
                          setForm({ ...form, end: true });
                        }
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckIndeterminate">
                      انقضا تخفیف قبلی
                    </label>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                {console.log("d", data)}
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                      },
                      { ...form }
                    )
                  }
                  // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "reply":
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">پاسخ نظر</div>
              </div>
              <div className="p-2">
                <div>
                  <p>{data?.data?.text}</p>
                </div>
                <div className="form-group my-2">
                  <label htmlFor="exampleInputName1">پاسخ شما</label>
                  <textarea
                    onChange={({ target: { value } }) => {
                      setForm({ ...form, text: value });
                    }}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    rows={4}
                    placeholder={"..."}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={handleClose}>
                  بستن
                </button>
                {console.log("d", data)}
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    deleteRow(
                      {
                        ...data,
                      },
                      { ...form }
                    )
                  }
                  // disabled={!form[data.action.objectKey]}
                >
                  {LoadingDelete ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                  ) : (
                    "ثبت"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    case "showdata":
      console.log("ssss", data.data.walletdate);
      return (
        <div
          className={`modal fade ${data.status && "show"} `}
          id="error-modal"
          tabIndex={-1}
          aria-modal="true"
          role="dialog"
          style={{
            backgroundColor: "rgba(0,0,0,0.6)",
            display: data.status ? "block" : "none",
          }}>
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: 500 }}>
            <div className="modal-content position-relative">
              <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                <button
                  className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="نزدیک"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body p-0">
                <div className="p-3">اطلاعات مورد نیاز</div>
              </div>
              <div className="p-2">
                <div className="d-flex badge-license  align-items-center">
                  <p>
                    نوع شبکه انتقال :{" "}
                    {data.data.walletdate.tranc_network == "bank"
                      ? " کارت به کارت"
                      : data.data.walletdate.tranc_network}
                  </p>
                </div>
                <div className="license-card">
                  <div>
                    <p>{data.data.walletdate.wallet_addres}</p>
                  </div>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        data.data.walletdate.wallet_addres
                      );
                      toast.success("آدرس با موفقیت کپی شد");
                    }}>
                    <img
                      alt="کد آدرس"
                      src={
                        window.origin + "/assets/img/broken/document-copy.svg"
                      }
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      break;
  }
  return;
};
const SelectSaleexperts = ({ form, setForm }) => {
  const [loadingNeed, setLoadingNeed] = useState(true);
  const [data, setData] = useState({ saleexperts: [] });

  const getNesdata = async () => {
    setLoadingNeed(false);
    const result = await post("/nesdata", { needkey: ["saleexperts"] });
    setData(result.data);
    setLoadingNeed(false);
  };
  useEffect(() => {
    getNesdata();
  }, []);

  return (
    <MultiSelect
      loading={loadingNeed}
      element={{
        label: "کارشناسان",
        key: "experters",
      }}
      handleValue={() => {}}
      handleChange={(e, value) => {
        setForm({ ...form, experters: value.map((ele) => ele.value) });
      }}
      data={data["saleexperts"]}
      disible={form.all}
    />
  );
};

export default ModalContent;
