import React from "react";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { Calendar } from "react-multi-date-picker";
import { ConvertDate } from "../../../../Utils/functions";
const CustomDatePicker = ({ element, form, setForm, single = false }) => {
  if (element.single) {
    return (
      <div className="my-3">
        <label>{element.label}</label>
        <Calendar
          range={false}
          onChange={data => {
            console.log("da", data);
            if (data) {
              setForm({
                ...form,
                [element.key]: ConvertDate(
                  `${data.year}/${data.month.number}/${data.day}`
                ),
              });
            }
          }}
          calendar={persian}
          locale={persian_fa}
          // value={form[element.key]}
        />
      </div>
    );
  }
  return (
    <div className="my-3">
      <label>{element.label}</label>
      <Calendar
        range={true}
        onChange={data => {
          if (data.length > 1) {
            setForm({
              ...form,
              [element.key[0]]: ConvertDate(
                `${data[0].year}/${data[0].month.number}/${data[0].day}`
              ),
              [element.key[1]]: ConvertDate(
                `${data[1].year}/${data[1].month.number}/${data[1].day}`
              ),
            });
          }
        }}
        calendar={persian}
        locale={persian_fa}
      />
    </div>
  );
};

export default CustomDatePicker;
