import React from "react";
import OrderDetails from "./OrderDetails";

const Surfaces = ({ option }) => {
  switch (option.surfaceType) {
    case "orderDetails":
      return <OrderDetails option={option} />;
    default:
      break;
  }
  return <div>Surfaces</div>;
};

export default Surfaces;
