import { toast } from "react-toastify";
import { serialize } from "object-to-formdata";
import axios from "axios";
export const API_SERVER = "https://api.cotex.io/api";
// export const API_SERVER = "http://127.0.0.1:8000/api";
// axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Accept-Language"] = "fa";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

const user = JSON.parse(localStorage.getItem("user"));
if (user && user.access_token) {
  axios.defaults.headers.post["Authorization"] = `Bearer ${user.access_token}`;
}

export const server = axios.create({
  baseURL: API_SERVER,
  // headers: {
  //   "Content-Type": "application/json",
  //   Accept: "application/json",
  // },
});

export const get = async (url) => {
  try {
    return await server
      .get(url)
      .then((response) => {
        return response;
      })
      .error((err) => {});
  } catch (error) {}
};
export const post = async (url, body, message = false) => {
  try {
    const response = await server.post(url, serialize(body));
    if (message && response.data.message) {
      toast.success(response.data.message);
    }
    return {
      data: response.data.data,
      status: response.status,
    };
  } catch (error) {
    const {
      response: { status, data },
    } = error;
    switch (+status) {
      case 400:
        toast.error(data.message);
        break;
      case 401:
        localStorage.clear();
        window.location.replace("/");
        toast.error(data.message);
        break;
      case 500:
        toast.error(data.message);
      default:
        break;
    }
    return {};
  }
};
